





























import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';

import ExSlick from '@/components/Slick/index.vue';
import SisuScore from '../SisuScore/SisuScore.vue';

import {
  MAX_VALUE_SCORE,
  MIN_VALUE_SCORE,
  SISU_TOPICS,
  TITLE_CARD_REDACTION,
} from '../constants/SisuScore';

import { CardScore, ListCardScore } from '../interface/ISisuScore';

const TOTAL_ITEM_SLIDE = 5;
const MAX_CARD_SLIDE = 1;

@Component({
  components: {
    ExSlick,
    SisuScore,
  },
})
export default class SimulateAnotherScore extends Vue {
  @Prop() listSisuScore !: ListCardScore;

  private listCardScore: ListCardScore = [];

  private totalItemSlide = TOTAL_ITEM_SLIDE;
  private maxCardSlide = MAX_CARD_SLIDE;

  @Watch('listSisuScore', {
    immediate: true,
  })
  setListTopics() {
    this.listCardScore = this.listSisuScore.length ? this.listSisuScore : SISU_TOPICS;
  }

  @Watch('listCardScore', {
    immediate: true,
    deep: true,
  })
  emitListScore() {
    this.$emit('listScore', this.listCardScore);
    this.$emit('validation-listScore', this.validationListScore());
  }

  validationListScore() {
    return !this.listCardScore.some((cardScore: CardScore) => {
      if (cardScore.title === TITLE_CARD_REDACTION) {
        return !(Number(cardScore.score) >= 0 && Number(cardScore.score) <= MAX_VALUE_SCORE);
      }

      return !(Number(cardScore.score) > MIN_VALUE_SCORE && Number(cardScore.score) <= MAX_VALUE_SCORE);
    });
  }
}
