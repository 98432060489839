


























import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';

import {
  TITLE_CARD_REDACTION,
  MESSAGE_MAX_VALUE_SCORE,
  MESSAGE_MIN_VALUE_SCORE,
  MESSAGE_EMPTY_VALUE_SCORE,
  MESSAGE_MIN_VALUE_SCORE_REDACTION,
  MAX_VALUE_SCORE,
  MIN_VALUE_SCORE,
} from '../constants/SisuScore';

import { CardScore } from '../interface/ISisuScore';

@Component({})
export default class SisuScore extends Vue {
  @Prop() cardScore !: CardScore;

  private messageFeedbackScore = '';
  private maxValueScore = MAX_VALUE_SCORE;
  private minValueScore = MIN_VALUE_SCORE;

  @Watch('cardScore', {
    deep: true,
  })
  validationScore() {
    if (!this.cardScore.score) {
      this.messageFeedbackScore = MESSAGE_EMPTY_VALUE_SCORE;
      return;
    }

    if (Number(this.cardScore.score) > 1000) {
      this.messageFeedbackScore = MESSAGE_MAX_VALUE_SCORE;
      return;
    }

    if (Number(this.cardScore.score) <= 0 && this.cardScore.title !== TITLE_CARD_REDACTION) {
      this.messageFeedbackScore = MESSAGE_MIN_VALUE_SCORE;
      return;
    }

    if (Number(this.cardScore.score) < 0) {
      this.messageFeedbackScore = MESSAGE_MIN_VALUE_SCORE_REDACTION;
      return;
    }

    this.messageFeedbackScore = '';
  }

}
