
















































import { Vue, Component } from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import Footer from '@/components/Footer/Footer.vue';

import SimulateAnotherScore from '../../components/SimulateAnotherScore/SimulateAnotherScore.vue';
import SelectChoice from '../../components/SelectChoice/SelectChoice.vue';
import EnemSimulatorLoading from './EnemSimulatorLoading.vue';

import SimulationService from '@/services/Simulation/SimulationService';

import { CardScore, ListCardScore } from '../../components/interface/ISisuScore';
import { SelectOptions } from '../../components/SelectChoice/interface/ISelectOptions';
import { Result } from '../../interface/IResultSimulated';

import { STATUS_RESPONSE_API } from '@/constant/StatusResponseAPI';

const FIRST_DAY_SIMULATED = 0;
const SECOND_DAY_SIMULATED = 1;

@Component({
  components: {
    PageHeader,
    ContainerFluid,
    BoxContainer,
    SimulateAnotherScore,
    SelectChoice,
    EnemSimulatorLoading,
    Footer,
  },
})

export default class EnemSimulator extends Vue {
  private isLoading = true;
  private isValidationListScore = false;
  private isValidationSelectsOptions = false;
  private isSetValidationSelect = false;

  private topId: number | null = null;

  private listSisuScore: ListCardScore = [];

  private dataListScore: Array<CardScore> = [];

  private dataSelectsOptions: SelectOptions | null = null;

  private SimulationService = new SimulationService();

  async created() {
    await this.getSimulationTopId();
    this.getResultSimulated();
  }

  mounted() {
    this.setBreadCrumbs();
  }

  get id() {
    return this.$route.params?.id;
  }

  async getSimulationTopId() {
    try {
      this.isLoading = true;

      if (!this.id) return;

      const response = await this.SimulationService.getChildrenSimulated(Number(this.id));

      this.topId = response?.topId;
    } catch (error) {
      console.error(error);
    }
  }

  async getResultSimulated() {
    try {
      if (!this.topId) return;

      const response = await this.SimulationService.getResultSimulated(String(this.topId));

      if (response.status === STATUS_RESPONSE_API.SUCCESS_OK && response.data?.simulations) {
        this.listSisuScore = this.createListSisuScore(response.data?.simulations);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  createListSisuScore(resultSimulated: Array<Result>) {
    return [
      {
        title: 'Linguagens e Códigos',
        score: this.averageScore(Number(resultSimulated[FIRST_DAY_SIMULATED].scoreLanguage), Number(resultSimulated[SECOND_DAY_SIMULATED].scoreLanguage)),
        slug: 'languages',
      },
      {
        title: 'Ciências Humanas',
        score: this.averageScore(Number(resultSimulated[FIRST_DAY_SIMULATED].scoreHuman), Number(resultSimulated[SECOND_DAY_SIMULATED].scoreHuman)),
        slug: 'human',
      },
      {
        title: 'Ciências da Natureza',
        score: this.averageScore(Number(resultSimulated[FIRST_DAY_SIMULATED].scoreNature), Number(resultSimulated[SECOND_DAY_SIMULATED].scoreNature)),
        slug: 'nature',
      },
      {
        title: 'Matemática',
        score: this.averageScore(Number(resultSimulated[FIRST_DAY_SIMULATED].scoreMath), Number(resultSimulated[SECOND_DAY_SIMULATED].scoreMath)),
        slug: 'math',
      },
      {
        title: 'Redação',
        score: this.averageScore(Number(resultSimulated[FIRST_DAY_SIMULATED].scoreComposing), Number(resultSimulated[SECOND_DAY_SIMULATED].scoreComposing)),
        slug: 'composing',
      },
    ];
  }

  averageScore(scoreFirstDay: number, scoreSecondDay: number) {
    return (scoreFirstDay + scoreSecondDay) / 2;
  }

  setListScore(listScore: Array<CardScore>) {
    this.dataListScore = listScore;
  }

  validationListScore(isValidationListScore: boolean) {
    this.isValidationListScore = isValidationListScore;
  }

  setSelectOptions(selectOptions: SelectOptions) {
    this.dataSelectsOptions = selectOptions;
  }

  validationSelects(isValidationSelect: boolean) {
    this.isValidationSelectsOptions = isValidationSelect;
  }

  handleSimulatorResult() {
    if (!this.isValidationListScore || !this.isValidationSelectsOptions) {
      this.isSetValidationSelect = true;
      return;
    }

    this.setCreateOptionsSimulatorStore();

    this.$router.push({
      name: 'SimulatorResult',
      params: {
        id: this.$route.params.id,
      },
    });
  }

  createOptionsSimulator() {
    return {
      score: this.dataListScore,
      ...this.dataSelectsOptions,
    };
  }

  setCreateOptionsSimulatorStore() {
    this.$store.commit('setOptionsSimulator', this.createOptionsSimulator());
  }

  setBreadCrumbs() {
    if (this.id) {
      const pageResult = `/simulado/${this.id}/resultado`;

      this.$breadcrumb.set([
        { title: 'Simulados', to: '/simulados' },
        { title: 'Resultado ', to: pageResult },
        { title: 'Simulador SISU ', to: null },
      ]);

      return;
    }

    this.$breadcrumb.set([
      { title: 'Simulados', to: '/simulados' },
      { title: 'Simulador SISU ', to: null },
    ]);
  }
}
