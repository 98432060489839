import { CardScore } from '../interface/ISisuScore';

const TITLE_CARD_REDACTION = 'Redação';
const MESSAGE_MAX_VALUE_SCORE = 'Limite máximo permitido: 1000';
const MESSAGE_MIN_VALUE_SCORE_REDACTION = 'Limite mínimo permitido: 0';
const MESSAGE_MIN_VALUE_SCORE = 'Limite mínimo permitido: 1';
const MESSAGE_EMPTY_VALUE_SCORE = 'Campo obrigátorio';
const MAX_VALUE_SCORE = 1000;
const MIN_VALUE_SCORE = 0;

const SISU_TOPICS: Array<CardScore> = [
  {
    title: 'Linguagens e Códigos',
    score: '1',
    slug: 'languages',
  },
  {
    title: 'Ciências Humanas',
    score: '1',
    slug: 'human',
  },
  {
    title: 'Ciências da Natureza',
    score: '1',
    slug: 'nature',
  },
  {
    title: 'Matemática',
    score: '1',
    slug: 'math',
  },
  {
    title: 'Redação',
    score: '0',
    slug: 'composing',
  },
];

export {
  TITLE_CARD_REDACTION,
  MESSAGE_MAX_VALUE_SCORE,
  MESSAGE_MIN_VALUE_SCORE,
  MESSAGE_EMPTY_VALUE_SCORE,
  MESSAGE_MIN_VALUE_SCORE_REDACTION,
  MAX_VALUE_SCORE,
  MIN_VALUE_SCORE,
  SISU_TOPICS,
};
